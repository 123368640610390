// this file is auto generated, do not edit it directly
module.exports = {
  "rootProjectId": "root",
  "stage": "PROD",
  "captchaKey": "6Le3D4opAAAAAJNgbWWi12gB-vYCQZPK1wy0WX05",
  "region": "ap-south-1",
  "cosUrl": "api.costaelr.retter.io",
  "version": "2.0.31",
  "appUrl": "https://costaelr.c.retter.io/",
  "uiBucketName": "costaelr.c.retter.io",
  "uiBucketDomainName": "costaelr.c.retter.io"
}